@font-face { font-family: 'TisaWebPro';
  src: url('/_s/fonts/TisaWebPro.ttf');
  src: url('/_s/fonts/TisaWebPro.woff') format('woff'), url("/_s/fonts/TisaWebPro.woff2") format("woff2"), url('/_s/fonts/TisaWebPro.ttf') format('truetype');
  font-style: normal;
}

@font-face { font-family: 'TisaWebPro';
  src: url('/_s/fonts/TisaWebPro-Italic.ttf');
  src: url('/_s/fonts/TisaWebPro-Italic.woff') format('woff'), url("/_s/fonts/TisaWebPro-Italic.woff2") format("woff2"), url('/_s/fonts/TisaWebPro-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'MobiliarRealist';
  src: url("/_s/fonts/MobiliarRealistWeb-SemiLight.eot?#iefix");
  src: url("/_s/fonts/MobiliarRealistWeb-SemiLight.eot?#iefix") format("eot"), url("/_s/fonts/MobiliarRealistWeb-SemiLight.woff2") format("woff2"), url("/_s/fonts/MobiliarRealistWeb-SemiLight.woff") format("woff"), url("/_s/fonts/MobiliarRealistWeb-SemiLight.ttf") format("truetype"), url("/_s/fonts/MobiliarRealistWeb-SemiLight.svg#MobiliarRealistWeb-SemiLight") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'MobiliarRealist';
  src: url("/_s/fonts/MobiliarRealistWeb-Bold.eot?#iefix");
  src: url("/_s/fonts/MobiliarRealistWeb-Bold.eot?#iefix") format("eot"), url("/_s/fonts/MobiliarRealistWeb-Bold.woff2") format("woff2"), url("/_s/fonts/MobiliarRealistWeb-Bold.woff") format("woff"), url("/_s/fonts/MobiliarRealistWeb-Bold.ttf") format("truetype"), url("/_s/fonts/MobiliarRealistWeb-Bold.svg#MobiliarRealistWeb-Bold") format("svg");
  font-weight: 700;
  font-style: normal; }

$font-family-sans-serif: 'MobiliarRealist', sans-serif;
$fa-font-path: "../fonts";
$modal-backdrop-opacity: 0.6;

$input-border-radius: 0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;
$modal-inner-padding: 20px;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  590px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


$navbar-inverse-bg: rgb(218, 35, 35);
$navbar-inverse-color: #FFFFFF;
$navbar-inverse-link-color: #FFFFFF;

// Import bootstrap and fontawesome
@import "bootstrap";
@import "font-awesome";
//@import "/_s/autocomplete_light/vendor/select2/dist/css/select2.css";
//@import "select2-bootstrap";

h1, h2 {
  font-family: "TisaWebPro", serif;
  color: rgb(218, 35, 35) !important;
}

h3, h4, h5, h6 {
  color: rgb(218, 35, 35) !important;
  font-weight: 700;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 20px;
  color: black !important;
}

.modal h3 {
  margin-top: 0;
  margin-bottom: 4px;
}

.modal .modal-description {
  font-size: 20px;
  margin-bottom: 25px;
}

.btn {
  text-transform: uppercase;
  padding: 10px 20px 11px;
  border: 0;
  font-size: 15px;
  line-height: 19.95px;
  font-weight: 700;
}

.btn-sm {
  padding: 5px 10px 6px;
  font-size: 12px;
}

.btn:active:focus, .btn:focus {
  outline: none;
}

.btn-primary {
  color: white;
  background-color: rgb(218, 35, 35);
  border-color: rgb(218, 35, 35);
}
.btn-primary:focus {
  color: white;
  background-color: rgb(218, 35, 35);
  border-color: rgb(218, 35, 35);
}
.btn-primary:active {
  color: white;
  background-color: rgb(218, 35, 35);
  border-color: rgb(218, 35, 35);
}
.btn-primary:visited {
  color: white;
  background-color: rgb(218, 35, 35);
  border-color: rgb(218, 35, 35);
}
.btn-primary:hover {
  color: white;
  background-color: rgb(255, 40, 40);
  border-color: rgb(255, 40, 40);
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: white;
  background-color: rgb(255, 40, 40);
  border-color: rgb(255, 40, 40);
}

/* Modals */
.modal-content {
  border: 0;
  border-radius: 0;
}

.modal-header {
  border: 0;
}

/* Form Controls */
.form-control {
  border: 0;
  border-bottom: 1px solid rgb(150, 155, 160);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  color: #555 !important;;
}

.form-control:focus{
  border-color: #007b91;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.horizontal-form label {
  padding-right: 0;
}

p {
  font-size: 20px;
  color: #000000;
}

.has-error .form-control {
  border-color: #da2323;
  box-shadow: none;
}

.has-error .form-control:focus {
  border-color: #da2323;
  box-shadow: none;
}

.asteriskField::before {
  content: " ";
}

/*.asteriskField {
  display: none;
}*/

a {
  color: #da2323;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer;
}

a:hover, a:focus {
  color: #bb1e1e;
  text-decoration: none;
}

a:visited {
  color: #da2323;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #da2323;
}

input[type='text'], input[type='search'], input[type='email'], input[type='tel'], input[type='url'], input[type='password'], input[type='date'], input[type='month'], input[type='week'], input[type='time'], input[type='number'], input[type='file'], select, textarea {
  font-size: 20px;
}

label {
  font-size: 15px;
}

.controls p {
  font-size: 14px;
}

html {
  font-size: 20px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
}

body {
  line-height: 1.45;
  -webkit-font-smoothing:antialiased;
  color: #333 !important;
}

h1, h2, .h1, .h2 {
  font-family: "TisaWebPro", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.25;
  text-rendering: auto;
  color: #da2323;
}

h3, h4, h5, h6 {
  font-family: "TisaWebPro", serif;
  font-weight: 400;
  font-style: normal;
  text-rendering: auto;
}

h4 {
  font-family: "MobiliarRealist",Arial,sans-serif;
  font-size: 20px;
  color: black;
  font-weight: bold;
}
h1 {
  font-weight: inherit;
  font-size: 32px;
  line-height: 40px;
}

// Mobi Radios and Checkboxes

.checkbox {
  padding-left: 20px; }
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px; }
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 1px;
  margin-left: -20px;
  border: solid 2px #969ba0;
  border-radius: 4px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 4px;
  background-color: #007b91 !important;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  content: " ";

  -webkit-transition: all 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
  -moz-transition: -moz-transform 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
  -o-transition: -o-transform 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
  transition: all 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
}
.checkbox input[type="checkbox"] {
  opacity: 0; }
.checkbox input[type="checkbox"]:focus + label::before {
  outline: none;
}
.checkbox input[type="checkbox"]:checked + label::before {
  border-color: #696d70;
}


input[type="checkbox"].checkboxinput:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
  border-radius: 50%; }
.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  border: solid 2px #969ba0;
  border-radius: 10px;
  left: 0;
  top: 6px;
  margin-left: -20px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  left: 4px;
  top: 10px;
  margin-left: -20px;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);

  background: #007b91 !important;
  border-radius: 10px;
  -webkit-transition: all 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
  -moz-transition: -moz-transform 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
  -o-transition: -o-transform 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;
  transition: all 200ms cubic-bezier(0.25,0.1,0.25,1) 0s;

}
.radio input[type="radio"] {
  opacity: 0;
}
.radio input[type="radio"]:focus + label::before {
  outline: none;
  border-color: #696d70;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio .radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #428bca;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #969ba0;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #428bca;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #007b91 !important; }
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #696d70; }
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #007b91 !important; }

.radio label {
  font-size: 20px;
  margin-top: -5px;
}
#logo {
  position: fixed;
  z-index: 21;
  top:0;
  left:15px;
  background-color: rgb(218, 35, 35);
  height: 60px;
  width: 110px;
  text-align: center;
}

.navbar-default .navbar-nav > li > a.active {
  color: black;
  font-weight: bold;
}

.progress-bar-danger {
  background-color: #da2323;
}

.select, .selectmultiple {
  background-color: #f0f0eb;
  background-repeat: no-repeat;
  padding-left: 5px;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
}

textarea.form-control {
  border: 1px solid rgb(150, 155, 160);
  padding: 5px;
}

.table tbody>tr>td {
  vertical-align: middle;
}

@media (min-width: 590px) {
  .pull-right-sm {
    float: right;
  }
}

.liked {
  color: #da2323;
}

.unliked {
  color: #333333;
}

.thumbnail {
  border-radius: 0;
  border: 0;
  padding: 0;
}

.thumbnail .caption {
  padding: 7px;
  color: white;
}

.header__logo-image {
  width: 100%;
  max-width: 135px;
  height: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* Mobi Stuff */
@media (min-width: 768px) {
  .header__logo-image {
    max-width: 135px;
  }
}

.breadcrumbs a {
  color: grey;
}

.navbar-text {
  font-size: 15px;
  margin-top: 40px;
}

/*
.control-label {
  font-weight: normal;
}
.requiredField {
  font-weight: bold;
}*/

/* Secondary Button */
.btn-secondary {
  color: black;
  font-weight: normal;
  background-color: white;
  border: 1px solid #eceff1;
  text-transform: none;
  padding: 8px 18px 10px;
  font-size: 16px;
}
.btn-secondary:focus {
  color: black;
  background-color: white;
  border: 1px solid #eceff1;
  text-transform: none;
}
.btn-secondary:active {
  color: black;
  background-color: white;
  border: 1px solid #eceff1;
  text-transform: none;
}
.btn-secondary:visited {
  color: black;
  background-color: white;
  border: 1px solid #eceff1;
  text-transform: none;
}
.btn-secondary:hover {
  color: black;
  background-color: white;
  border: 1px solid #cfd8dc;
  text-transform: none;
}

.btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus, .open > .btn-secondary.dropdown-toggle:hover, .open > .btn-secondary.dropdown-toggle:focus, .open > .btn-secondary.dropdown-toggle.focus {
  color: black;
  background-color: white;
  border: 1px solid #cfd8dc;
}

.asteriskField {
  display: none;
}

/* For gallery views to ensure that boxes don't shift rows */
.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}